'use client'
import { LoggingInContent } from '../../features/auth/organisms/LoggingInContent'
import * as React from 'react'
import { useLogInState } from '../../features/auth/authState'
import { useSearchParams } from 'next/navigation'

export default function LoginPage() {
  const searchParams = useSearchParams()
  const [, setLogInState] = useLogInState()
  React.useEffect(() => {
    setLogInState((s) => ({ ...s, isLoggingIn: true, loginOptions: searchParams ?? {} }))
  }, [setLogInState, searchParams])
  // for SSR, render this content. on client, when effect runs and sets isLoggingIn to true, this component will
  // unmount, but this will prevent content change
  return <LoggingInContent />
}
